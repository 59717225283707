var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"finances-page pb-3"},[_c('div',{staticClass:"controls-row row mb-4"},[_c('div',{staticClass:"col finances-page__select-range"},[_c('CustomSelect',{attrs:{"type":"radiogroup","disabled":_vm.isLoading,"options":_vm.customPeriodOptions,"selected":_vm.selectedRange},on:{"update:selected":function($event){_vm.selectedRange=$event}}})],1),_c('span',{staticClass:"excel-icon",on:{"click":_vm.earningsExport}})]),_c('Pane',[_c('div',{staticClass:"row"},[_c('Loader',{attrs:{"visible":_vm.isChartLoading}}),_c('div',{staticClass:"col-8"},[_c('div',{staticClass:"chart-controls"},[_c('CustomSelect',{attrs:{"type":"select","options":_vm.earningsChartOptions,"selected":_vm.earningsConfig.metric},on:{"update:selected":[function($event){return _vm.$set(_vm.earningsConfig, "metric", $event)},function($event){return _vm.loadEarningsChart()}]}})],1),_c('apexchart',{attrs:{"type":"line","height":"200","options":_vm.chartOptions(_vm.findMin(_vm.sortedCharts), _vm.findMax(_vm.sortedCharts)),"series":_vm.sortedCharts}})],1),_c('div',{staticClass:"col-4"},[(_vm.getTotals)?_c('div',{staticClass:"total-stats"},[_c('div',{staticClass:"stat-total"},[_vm._v(" "+_vm._s(_vm.earningsConfig.metric.label)+" — "+_vm._s(_vm.formatNumberCompact(_vm.getTotals[_vm.earningsConfig.metric.value]))+" "+_vm._s(!_vm.isChartLoading ? _vm.getChartMetric.suffix : "")+" ")])]):_vm._e(),_c('div',{staticClass:"chart-legend"},_vm._l((_vm.earningsLegend),function(element,index){return _c('div',{key:index,staticClass:"chart-legend__element"},[_c('span',{staticClass:"chart-legend__marker",style:(`background-color: ${
                _vm.chartOptions(_vm.findMin(_vm.sortedCharts), _vm.findMax(_vm.sortedCharts))
                  .colors[index]
              }`)}),_vm._v(" "+_vm._s(element.name || element.url || _vm.countries[element.country] || _vm.$t('common.allWorld'))+" ")])}),0)])],1)]),_c('Pane',{staticClass:"mt-4"},[(
        !_vm.isSubIdsLoading &&
        _vm.isSubIds &&
        !_vm.shownDetail &&
        _vm.earningsConfig.group !== 'country'
      )?_c('CustomSelect',{staticClass:"ml-1 mb-3",attrs:{"type":"select","options":_vm.subIdsOptions,"selected":_vm.subId},on:{"update:selected":[function($event){_vm.subId=$event},function($event){return _vm.loadEarnings()}]}}):_vm._e(),_c('Loader',{attrs:{"visible":_vm.isLoading}}),_c('DataGrid',{attrs:{"data":_vm.getEarnings,"model":_vm.earningsModel,"detailedRequestConfig":_vm.detailedRequestConfig,"count-total":"","expansible":""},on:{"update:col":function($event){_vm.earningsConfig.group = $event.value.group || $event.value.field;
        _vm.loadEarnings();},"update:detailShown":_vm.onHostDetailShown}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }